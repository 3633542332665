<template>
  <div class="product-grid bg-white">
    <div class="d-flex flex-column">
      <div class="ss-header">
        <span class="title-desc"
          ><strong>Кому вы желаете адресовать свой вопрос?</strong></span
        >
      </div>
      <select
        v-model="selected"
        @change="findByName"
        class="contacts-select mt-3"
      >
        <option
          v-for="option in options"
          v-bind:value="option.name"
          :selected="option.name === selected"
          :key="option.id"
        >
          {{ option.name }}
        </option>
      </select>
      <div class="ss-header">
        <span class="title-desc"><strong>Контактные адреса</strong></span>
      </div>
      <div class="ss-body pb-0" v-html="contact.content"></div>
      <div class="ss-body p-0" v-if="contact.contacts">
        <ul class="list-unstyled contacts">
          <li v-for="item in contact.contacts" :key="item.id" class="mb-3">
            <span class="d-inline-block mb-2">{{ item.full_name }}</span>
            <ul class="list-unstyled">
              <li
                :style="{
                  width: contact.name === 'Сувенирная продукция' ? '203px' : '',
                  lineHeight:
                    contact.name === 'Сувенирная продукция' ? '120%' : '',
                }"
              >
                {{ item.phone }}
              </li>
              <li>{{ item.email }}</li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="contact-form" v-if="selected === 'Техническая поддержка'">
        <div class="ss-header">
          <span class="title-desc"><strong>Обратная связь</strong></span>
        </div>
        <textarea
          class="input_style"
          v-model="message"
          type="text"
          placeholder="Сообщение в техническую поддержку"
        ></textarea>
        <button
          class="send-to-basket"
          type="button"
          :disabled="message.length < 10"
          :class="message.length < 10 ? 'disabled' : ''"
          @click="sendEmail"
        >
          Отправить
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { get, post } from "@/lib/axios";
import { showSuccess } from "@/lib/swal2";

export default {
  name: "Contacts",
  data: () => ({
    options: [],
    selected: "Техническая поддержка",
    contact: [],
    message: "",
  }),
  methods: {
    findByName() {
      post("support/find-by-name", { topic: this.selected }).then(
        (response) => {
          this.contact = response.data.data;
        }
      );
    },
    async fetchData() {
      await get("support").then((response) => {
        this.options = response.data.data;

        if (this.options) {
          let selected;

          if (this.isCurrentUserDealer)
            // если для дилера
            selected = this.options.find(
              (c) => c.name == "Техническая поддержка"
            );
          else
            selected = this.options.find(
              (c) => c.name == "Вопрос по работе портала"
            );

          if (selected) {
            this.selected = selected.name;
          }
        }

        this.findByName();
      });
    },
    async sendEmail() {
      await post("support/send", { message: this.message }).then(() => {
        showSuccess("Ваше сообщение успешно отправлено!");
        this.message = "";
      });
    },
  },
  mounted() {
    this.fetchData();
  },
  metaInfo() {
    return {
      title: `Профиль - Контакты - ЛУКМАРКЕТ`,
    };
  },
};
</script>

<style scoped lang="scss">
.disabled {
  background-color: #777;
  border-color: #777;
  cursor: not-allowed;
}

.ss-header {
  padding: 20px 0;
  border-bottom: 2px solid #f5f5f5;
}

.ss-body {
  padding: 15px 0;
  font-size: 16px;
  line-height: 1.06;
  letter-spacing: normal;
  text-align: left;
}

.contacts {
  li {
    margin-bottom: 0.5rem;
  }

  &-select {
    max-width: 315px;
    padding: 6px;
  }
}

.input_style {
  padding: 20px;
  margin-bottom: 25px;
  width: 100%;
  max-width: 100%;
  min-height: 300px;
}
</style>
